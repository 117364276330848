<template>
	<div class="header">{{title}}</div>
</template>

<script>
	export default {
		props: {
			title: {
				default: '',
				type: String
			}
		}
	}
</script>

<style scoped>
	.header{
		font-family: 'PingFang SC ', 'PingFang SC', sans-serif;
		font-weight: 200;
		font-style: normal;
		font-size: 18px;
		line-height: 24px;
		color: #1E1E1E;
		letter-spacing: normal;
		padding-left: 10px;
		margin-bottom: 20px;
		position: relative;
	}
	.header::before{
		content: "";
		width: 4px;
		height: 24px;
		background-color: #ff9900;
		position: absolute;
		top: 0;
		left: 0;
	}
</style>
