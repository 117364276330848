<template>
    <div class="main-content">
      <pagesHeader :title="`${id == 0 ? '添加':'编辑'}资讯`" />
        <avue-form ref="form" v-model="form" :option="option" :upload-error="uploadError" :upload-delete="uploadDelete"
                   :upload-after="uploadAfter">
          <template slot="content">
            <avue-ueditor v-model="form.content" :options="uOptions"></avue-ueditor>
          </template>
            <template slot="menuForm">
                <div class="submit-button">
                  <el-button type="primary" size="medium" @click="handleSubmit(form)">确定</el-button>
                  <el-button size="medium" @click="$router.go(-1)">取消</el-button>
                </div>
            </template>
        </avue-form>
    </div>
</template>

<script>
    import column from "./column"
    import { infoManageAdd,infoManageView,infoManageUpdate,infoManageClassify } from "@/api/info/manage"
    import {actionUrl} from "@/config/public";
    export default {
        name: "increase",
        data(){
            return{
                id: this.$route.params.id || 0,
                form: {},
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth: 120,
                    column: column.increaseColumn
                },
              uOptions: {
                action: actionUrl,
                headers: {
                  Authorization: this.$store.state.user.Token
                },
                customConfig: {},
                props: {
                  url: 'path',
                  res: 'data',
                  name: 'path'
                }
              },
            }
        },
        mounted() {
            const mainImage = this.findObject(this.option.column, 'mainImage');
            mainImage.headers.Authorization = this.$store.state.user.Token;
          const video = this.findObject(this.option.column, 'video');
          video.headers.Authorization = this.$store.state.user.Token;
            this.GET_infoManageClassify();
            if(this.$route.params.id != 0){
                this.GET_memberView();
            }
        },
        methods: {
            salaryChange(e,t){
                this.form[t] = e.target.value;
            },
            handleSubmit(form){
                // console.log(form)
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                        if(this.$route.params.id == 0){
                            this.GET_memberAdd(form,done);
                        }else{
                            this.GET_memberUpdate(form,done);
                        }
                    }
                })
            },
            GET_memberAdd(form,done){
              infoManageAdd({
                title: form.title,
                classifyId: form.classifyId,
                sort: form.sort,
                jumpLink: form.jumpLink,
                mainImage: form.mainImage,
                video: form.video,
                content: form.content,
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
          GET_infoManageClassify(){
            infoManageClassify().then(res=>{
              if(res.code===200){
                const classifyId = this.findObject(this.option.column, 'classifyId');
                classifyId.dicData = res.data;
              }else{
                this.$message.error(res.msg)
              }
            }).catch(err=>{
              this.$message.error(err.msg)
            })
          },
            GET_memberUpdate(form,done){
              infoManageUpdate({
                    id: form.id,
                    title: form.title,
                    classifyId: form.classifyId,
                    sort: form.sort,
                     jumpLink: form.jumpLink,
                    mainImage: form.mainImage,
                    video: form.video,
                    content: form.content,
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            uploadAfter(res, done,loading,column) {
                done();
                this.$nextTick(() => {
                  if(column.prop === 'mainImage'){
                    this.form.mainImage = res.path;
                  }else{
                    this.form.video = res.path;
                  }
                });
            },
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadError(error, column) {
                this.$message.error(error);
                // console.log(error, column)
            },
            GET_memberView(){
              infoManageView(this.$route.params.id).then(res=>{
                    // console.log(res)
                    if(res.code===200){
                        this.form = res.data;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            checkNumber(value,key){
                if(value.length == 1){
                    this[key] = this[key].replace(/[^1-9]/g,'')
                }else{
                    this[key] = this[key].replace(/\D/g,'')
                }
            }
        }
    }
</script>

<style scoped>

</style>
