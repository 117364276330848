<template>
    <div class="search">
        <el-button type="primary"
                   class="margin" @click="increase">新建</el-button>
        <el-input v-model="form.name" placeholder="请输入姓名/手机号码" class="margin"></el-input>
        <el-button type="primary" @click="searchQuery" style="margin-bottom: 10px">搜索</el-button>
        <el-button type="info" @click="searchReset" style="margin-bottom: 10px">重置</el-button>
      <el-button type="primary" style="margin-bottom: 10px;margin-left: 100px" @click="clickExport" class="margin">下载导入模板</el-button>
      <el-upload
          :headers="headers"
          :action="action"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :on-error="handleAvatarError"
          :before-upload="beforeUpload"
          multiple
          type="primary"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          :limit="3"
          :file-list="fileList"
          style="margin:0 20px 10px;"
      >
        <el-button type="info" style="background: #f59a23;border: none;height: 40px;">批量导入</el-button>
      </el-upload>
    </div>
</template>

<script>
    import { baseUrl } from '@/config/public.js';
    export default {
        name: 'search',
        data() {
            return {
              action: `${baseUrl}/web/member/white/import`,
              headers: {
                Authorization: this.$store.state.user.Token
              },
              fileList: [],
                form: {
                    name: '', //活动标题
                },
            };
        },
        methods: {
            increase(){
                this.$router.push({ name: 'memberWhiteIncrease', params: { id: 0 } });
            },
            searchQuery() {
                this.$emit('changeSearch', this.form);
            },
            searchReset() {
                this.form = {
                    name: '', //活动标题
                };
                this.$emit('changeSearch', this.form);
            },
            clickExport(){
              this.$confirm("是否下载模板?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
              }).then(() => {
                window.open(`/api/web/member/white/export`,'_self');
              });
            },
          // 文件校验
          beforeUpload(file) {
            // 文件类型
            const isType = file.type === 'application/vnd.ms-excel';
            const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileType = isType || isTypeComputer;
            if (!fileType) {
              this.$message.error('上传文件只能是xls/xlsx格式！');
            } // 文件大小限制为2M
            const fileLimit = file.size / 1024 / 1024 < 20;
            if (!fileLimit) {
              this.$message.error('上传文件大小不超过2M！');
            }
            return fileType && fileLimit;
          },
          // 导入成功
          handleAvatarSuccess(res, file) {
            console.log(res,file)
            if (res.code == 200) {
              this.$message.success(res.msg);
              this.$root.eventHub.$emit('changeUserManage', true);
            } else {
              this.$message.error(res.msg);
            }
          },
          handleAvatarError(err){
            const e = JSON.parse(err.message);
            this.$message.error(e.msg);
          }
        }
    };
</script>

<style scoped>
    .search {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
    }
    .search > .el-input {
        width: 300px;
    }
    .margin{
        margin-bottom: 10px;
        margin-right: 10px;
    }
</style>
