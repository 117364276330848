import {
    imageUrl,
    actionUrl
} from '@/config/public';
const column = {
    column: [
        {
            label: 'ID',
            prop: 'id'
        },
        {
            label: '荣誉名称',
            prop: 'title'
        },
        {
            label: '图片',
            prop: 'image',
            type: 'img'
        },
        {
            label: '排序',
            prop: 'sort'
        },
        {
            label: '创建时间',
            prop: 'createTime'
        }
    ],
    increaseColumn: [
        {
            label: '荣誉标题',
            prop: 'title',
            span: 15,
            maxlength: 10,
            showWordLimit:true,
            placeholder:'不超过10个字',
            rules: [{
                required: true,
                message: '请输入荣誉标题',
                trigger: 'blur'
            }]
        },
        {
            label: '排序',
            prop: 'sort',
            span: 15,
            type: 'number',
            controls:false,
            minRows: 0,
            maxRows: 99999999,
            placeholder:'数字越大越靠前',
            row: true,
            rules: [{
                required: true,
                message: '请输入排序',
                trigger: 'blur'
            }]
        },
        {
            label: '图片',
            prop: 'image',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/*',
            loadText: '附件上传中，请稍等',
            tip: '支持JPG、PNG，建议上传尺寸232px*232px',
            fileSize: 1024 * 20,
            span: 24,
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path',
                home: imageUrl
            },
            headers: {
                Authorization: ''
            },
            action: actionUrl,
            rules: [{
                required: true,
                message: '请上传图片',
                trigger: 'blur'
            }]
        },
        {
            label: '荣誉简介',
            prop: 'honorProfile',
            type: 'textarea',
            span: 15,
            row: true,
            maxlength: 70,
            showWordLimit:true,
            placeholder:'<=70字符',
            rules: [{
                required: true,
                message: '请输入荣誉简介',
                trigger: 'blur'
            }]
        },
        {
            label: '详情',
            span: 20,
            prop: 'details',
            slot: true,
            rules: [{
                required: true,
                message: '请输入详情',
                trigger: 'blur'
            }]
        }
    ]
}
export default column
