const activityMarket = {
    state: {
        questions: [],
        questions_type: false,
        quotaAllocationID: '',
        info: {}
    },
    mutations: {
        SET_questions: (state,data) => {
            return state.questions = data
        },
        SET_questions_type: (state,data) => {
            return state.questions_type = data
        },
        SET_quotaAllocationID: (state,id) => {
            return state.quotaAllocationID = id
        },
        SET_info: (state,form) => {
            return state.info = form
        }
    },

}
export default activityMarket