const column = {
    column: [
        {
            label: 'ID',
            prop: 'id'
        },
        {
            label: '事件时间',
            prop: 'date'
        },
        {
            label: '事件内容',
            prop: 'content'
        },
        {
            label: '创建时间',
            prop: 'createTime'
        }
    ],
    increaseColumn: [
        {
            label: '企业事件',
            prop: 'content',
            type: "textarea",
            span: 15,
            placeholder:'字数不限制',
            rules: [{
                required: true,
                message: '请输入企业事件',
                trigger: 'blur'
            }]
        },
        {
            label: "年",
            span: 15,
            prop: "eventYear",
            type: "year",
            format: "yyyy",
            valueFormat: "yyyy",
            placeholder:'选择年份',
            rules: [{
                required: true,
                message: '请选择年',
                trigger: 'blur'
            }]
        },
        {
            label: "月",
            span: 15,
            prop: "eventMonth",
            type: "month",
            format: "MM",
            valueFormat: "MM",
            placeholder:'选择月份',
        }
    ]
}
export default column
