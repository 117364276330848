<template>
    <div class="main-content">
      <pagesHeader title="企业信息管理" />
        <avue-form ref="form" v-model="form" :option="option" :upload-error="uploadError" :upload-delete="uploadDelete"
                   :upload-after="uploadAfter">
          <template slot="fullName">
            <div style="display: flex">
              <el-input v-model="form.fullName"
                        autocomplete="off"
                        type="digit"
                        placeholder="不限制"
                        />
            </div>
          </template>
          <template slot="landline">
            <el-input type="tel" maxlength="20" style="height:32px" placeholder="20字以内" v-model="form.landline" clearable
                      onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
                      @blur="salaryChange"></el-input>
          </template>
          <template slot="mailbox">
            <el-input type="tel" maxlength="30" style="height:32px" placeholder="30字以内，不限制特殊符号" v-model="form.mailbox" clearable
                      onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
                      @blur="salaryChange"></el-input>
          </template>
          <template slot="wechatNumber">
            <el-input type="tel" maxlength="30" style="height:32px" placeholder="30字以内，不限制特殊符号" v-model="form.wechatNumber" clearable
                      onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
                      @blur="salaryChange"></el-input>
          </template>
          <template slot="phone">
            <el-input type="tel" maxlength="11" style="height:32px" placeholder="手机格式" v-model="form.phone" clearable
                      onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                      @blur="salaryChange"></el-input>
          </template>
          <template slot="region">
            <div style="display: flex">
              <el-select v-model="form.provinceId" placeholder="选择省">
                <el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
              <el-select v-model="form.cityId" placeholder="选择市">
                <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
              <el-select v-model="form.countyId" placeholder="选择区">
                <el-option v-for="item in county" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </template>
          <template slot="enterpriseIntroduce">
            <avue-ueditor v-model="form.enterpriseIntroduce" :options="uOptions"></avue-ueditor>
          </template>
          <template slot="menuForm">
            <div class="submit-button">
              <el-button type="primary" size="medium" @click="handleSubmit(form)">确定</el-button>
<!--              <el-button size="medium">取消</el-button>-->
            </div>
          </template>
        </avue-form>
    </div>
</template>

<script>
    import column from "./column";
    import { infoView,infoUpdate } from "@/api/enterprise/info"
    import { GetArea } from "@/api"
    import {actionUrl} from "@/config/public";
    export default {
        name: 'Add',
        data() {
            return {
                id: this.$route.params.id || 0,
                dicData: [],
                content: [],
                form: {},
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth: 150,
                    column: column.increaseColumn
                },
              uOptions: {
                action: actionUrl,
                headers: {
                  Authorization: this.$store.state.user.Token
                },
                customConfig: {},
                props: {
                  url: 'path',
                  res: 'data',
                  name: 'path'
                }
              },
                province: [],
                city: [],
                county: [],
                provinceId: '',
                cityId: '',
            };
        },
        watch: {
          'form.provinceId'(value){
            console.log(value)
            if(value != ''){
              this.province.forEach(item=>{
                if(item.id === value){
                  this.form.province = item.name;
                }
              })
              if(this.provinceId != value){
                this.form.cityId = '';
                this.form.city = '';
                this.form.countyId = '';
                this.form.county = '';
                this.form.region = '';
                this.provinceId = value;
              }
              this.GET_GetArea(value,'city');
            }
          },
          'form.cityId'(value){
            if(value != ''){
              this.city.forEach(item=>{
                if(item.id === value){
                  this.form.city = item.name
                }
              })
              if(this.cityId != value){
                this.form.countyId = '';
                this.form.county = '';
                this.form.region = '';
                this.cityId = value;
              }
              this.GET_GetArea(value,'county');
            }
          },
          'form.countyId'(value){
            if(value != ''){
              this.county.forEach(item=>{
                if(item.id === value){
                  this.form.county = item.name;
                  this.form.region = item.name;
                }
              })
            }
          }
        },
        mounted() {
            this.GET_GetArea('0','province');
            const logo = this.findObject(this.option.column, 'logo');
            logo.headers.Authorization = this.$store.state.user.Token;
            const wechatCode = this.findObject(this.option.column, 'wechatCode');
            wechatCode.headers.Authorization = this.$store.state.user.Token;
            if (this.$route.params.id != 0) {
                this.GetRotationBannerView(this.$route.params.id);
            }

        },
        methods: {
            salaryChange(e){
                this.form.sort = e.target.value;
            },
            handleSubmit(form) {
                this.$refs.form.validate((vaild, done) => {
                    if (vaild) {
                      infoUpdate({
                        id: form.id,
                        fullName: form.fullName,
                        abbreviation: form.abbreviation,
                        logo: form.logo,
                        wechatCode: form.wechatCode,
                        phone: form.phone,
                        landline: form.landline,
                        mailbox: form.mailbox,
                        wechatNumber: form.wechatNumber,
                        province: form.province, //门店省份,
                        provinceId: form.provinceId, //门店省份Id
                        city: form.city, //门店城市
                        cityId: form.cityId, //门店城市Id
                        county: form.county, //门店区县
                        countyId: form.countyId, //门店区县Id
                        addressDetail: form.addressDetail,
                        enterpriseIntroduce: form.enterpriseIntroduce
                      }).then(res=>{
                        if(res.code===200){
                          this.$message.success(res.msg)
                        }else{
                          this.$message.error(res.msg)
                        }
                        done();
                      }).catch(err=>{
                        this.$message.error(err.msg)
                        done();
                      })
                    }
                });
            },
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadError(error, column) {
              // console.log(error, column)
              this.$message.error(error || '文件过大');
            },
            uploadAfter(res, done,loading,column) {
                done();
                this.$nextTick(() => {
                      if(column.prop === 'logo'){
                        this.form.logo = res.path;
                      }else{
                        this.form.wechatCode = res.path;
                      }
                });
            },
            GetRotationBannerView(id) {
              infoView(id).then(res => {
                    // console.log(res);
                    if (res.code === 200) {
                        this.form = res.data;

                       this.provinceId = res.data.provinceId;
                       this.cityId = res.data.cityId;
                       this.form.region = res.data.county;
                    }
                });
            },
          GET_GetArea(e,type){
            GetArea(e).then(res=>{
              // console.log(res)
              if(res.code === 200){
                if(type === 'province'){
                  this.province = res.data;
                }else if(type === 'city'){
                  this.city = res.data;
                }else{
                  this.county = res.data;
                }
              }else{
                this.$message.error(res.msg)
              }
            }).catch(err=>{
              this.$message.error(err.msg)
            })
          }
        }
    };
</script>

<style scoped>
    .submit-button {
        padding: 50px 0;
    }

    .submit-button .el-button {
        padding: 10px 50px;
    }

    .avue-form>>>.el-input__suffix {
        display: none;
    }
</style>
