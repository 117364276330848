import Vue from 'vue'
import Vuex from 'vuex'
import index from './modules/index'
import user from './modules/user'
import system from './modules/system'
import userManage from './modules/userManage'
import activityMarket from './modules/activityMarket'
import activityInfo from './modules/activityInfo'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        index,
        user,
        system,
        userManage,
        activityMarket,
        activityInfo
    }
})

export default store


