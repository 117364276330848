<template>
    <div class="main-content">
      <pagesHeader :title="`${id == 0 ? '添加':'编辑'}企业事件`" />
        <avue-form ref="form" v-model="form" :option="option" :upload-error="uploadError" :upload-delete="uploadDelete"
                   :upload-after="uploadAfter">
            <template slot="menuForm">
                <div class="submit-button">
                  <el-button type="primary" size="medium" @click="handleSubmit(form)">确定</el-button>
                  <el-button size="medium" @click="$router.go(-1)">取消</el-button>
                </div>
            </template>
        </avue-form>
    </div>
</template>

<script>
    import column from "./column"
    import { enterpriseEventAdd,enterpriseEventView,enterpriseEventUpdate } from "@/api/enterprise/event"
    export default {
        name: "increase",
        data(){
            return{
                id: this.$route.params.id || 0,
                form: {},
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth: 120,
                    column: column.increaseColumn
                },
            }
        },
        mounted() {
            if(this.$route.params.id != 0){
                this.GET_memberView();
            }
        },
        methods: {
            salaryChange(e,t){
                this.form[t] = e.target.value;
            },
            handleSubmit(form){
                console.log(form)
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                        if(this.$route.params.id == 0){
                            this.GET_memberAdd(form,done);
                        }else{
                            this.GET_memberUpdate(form,done);
                        }
                    }
                })
            },
            GET_memberAdd(form,done){
              enterpriseEventAdd({
                content: form.content,
                eventMonth: form.eventMonth+'',
                eventYear: form.eventYear
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            GET_memberUpdate(form,done){
                enterpriseEventUpdate({
                    id: form.id,
                    content: form.content,
                    eventMonth: form.eventMonth?form.eventMonth:'0',
                    eventYear: form.eventYear
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            uploadAfter(res, done) {
                done();
                this.$nextTick(() => {
                    this.form.image = res.path;
                });
            },
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadError(error, column) {
                this.$message.error(error);
                // console.log(error, column)
            },
            GET_memberView(){
                enterpriseEventView(this.$route.params.id).then(res=>{
                    // console.log(res)
                    if(res.code===200){
                        // this.form = res.data;
                        this.form.content = res.data.content;
                        this.form.eventYear = res.data.eventYear + '';
                        if(res.data.eventMonth == 0){
                            this.form.eventMonth = ''
                        }else{
                            this.form.eventMonth = res.data.eventMonth + '';
                        }
                        this.form.id = res.data.id;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            checkNumber(value,key){
                if(value.length == 1){
                    this[key] = this[key].replace(/[^1-9]/g,'')
                }else{
                    this[key] = this[key].replace(/\D/g,'')
                }
            }
        }
    }
</script>

<style scoped>

</style>
