const index = {
    state: {
        menu: [],
        menuSubButtons: [],
        router: '',
        SubMenuIndex: '',
        tableTabs: [{
            title: '首页',
            name: '0',
            url: '/',
            index: '1'
        }]
    },
    mutations: {
        //缓存菜单
        setMenu(state,info) {
            state.menu = info;
        },
        //缓存路由
        setRouter(state,info){
            state.router = info;
        },
        // 缓存sun-menu index
        setSubMenuIndex(state,index){
            state.SubMenuIndex = index
        },
        setMenuSubButtons(state,index){
            state.menuSubButtons = index
        },
        setTableTabs(state,info){
            state.tableTabs = info
        },

    },

}
export default index