let baseUrl = 'api';
// 上传图片接口
let actionUrl = baseUrl + '/web/tool/file/upload'
//视频上传
let videoUrl = baseUrl + '/web/tool/video/upload'
// 图片路劲
let imageUrl = 'https://admin.guangdongyiyue.com';

export {
    baseUrl,
    actionUrl,
    imageUrl,
    videoUrl
}
