const column = {
    column: [
        {
            label: '用户头像',
            prop: 'avatarUrl',
            type: 'img'
        },
        {
            label: '用户昵称',
            prop: 'nickname'
        },
        {
            label: '授权手机号码',
            prop: 'phone'
        },
        {
            label: 'openID',
            prop: 'openId'
        },
    ],

}
export default column
