<template>
    <el-dialog title="角色权限配置" :visible.sync="dialogVisible" width="30%" :before-close="handleClose"
        class="avue-dialog avue-dialog--top"
		:close-on-click-modal="false">
        <el-tree
                ref="tree"
                :props="props"
                :data="tabs"
                show-checkbox
                node-key="id"
                :default-expanded-keys="defaultCheckedKeys"
                :default-checked-keys="defaultCheckedKeys"
                @check-change="handleCheckChange"></el-tree>
        <span slot="footer" class="dialog-footer">
            <el-button :icon="clickType?'el-icon-circle-check':'el-icon-loading'" type="primary" size="small" @click="handleSubmit(clickType)">{{clickType?'确 定':'提交中'}}</el-button>
            <el-button icon="el-icon-circle-close" size="small" @click="handleClose">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { roleMenuView,roleMenuUpdate } from "@/api/system/role";
    export default {
        name: 'power',
        props: {
            dialogVisible: {
                default: false,
                type: Boolean
            },
            tabs: Array,
            choiceId: String
        },
        data() {
            return {
                props: {
                    label: 'name',
                    value: 'id',
                    isLeaf: 'leaf',
                },
                clickType: true,
                defaultCheckedKeys: [],
            };
        },
        mounted() {
            roleMenuView(this.choiceId).then(res => {
                this.defaultCheckedKeys = res.data
            }).catch(err =>{
                this.$message.error(err.msg)
            })
        },
        methods: {
            handleClose() {
                this.$root.eventHub.$emit('changeRolePower', false);
            },
            handleCheckChange(data, checked, indeterminate) {
                // console.log(data, checked, indeterminate);
                this.expandArray = this.$refs.tree.getCheckedKeys()
            },
            handleSubmit(){
                let that = this;
                roleMenuUpdate({
                    id: this.choiceId,
                    menuIds: that.$refs.tree.getCheckedKeys()
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$root.eventHub.$emit('changeRolePower', false);
                        this.$message.success('修改成功');
                    }else{
                        this.$message.error(res.msg);
                    }
                }).catch(err =>{
                    this.$message.error(err.msg);
                })
            }
        }
    };
</script>

<style>
.el-dialog .el-dialog__body{
    max-height: 700px;
    overflow: hidden;
    overflow-y: auto;
}
.avue-dialog--top .el-dialog{
    top: 0 !important;
}
</style>
