const activityInfo = {
    state: {
        ids: '',
    },
    mutations: {
        SET_activityIds: (state,data) => {
            return state.ids = data
        }
    },

}
export default activityInfo
