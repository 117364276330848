<template>
    <div class="main-content">
      <pagesHeader :title="`${id == 0 ? '新建':'编辑'}图文视频`" />
        <avue-form ref="form" v-model="form" :option="option" :upload-error="uploadError" :upload-delete="uploadDelete" :upload-before="uploadBefore"
                   :upload-after="uploadAfter">
          <template slot="details">
            <avue-ueditor v-model="form.details" :options="uOptions"></avue-ueditor>
          </template>
            <template slot="menuForm">
                <div class="submit-button">
                  <el-button type="primary" size="medium" @click="handleSubmit(form)">确定</el-button>
                  <el-button size="medium" @click="$router.go(-1)">取消</el-button>
                </div>
            </template>
        </avue-form>
    </div>
</template>

<script>
    import column from "./column"
    import { enterpriseImageAdd,enterpriseImageView,enterpriseImageUpdate } from "@/api/enterprise/image"
    import {actionUrl,videoUrl} from "@/config/public";
    export default {
        name: "increase",
        data(){
            return{
                id: this.$route.params.id || 0,
                form: {
                  contentLocation: 1,
                  content: 1,
                },
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth: 120,
                    column: column.increaseColumn
                },
              uOptions: {
                action: actionUrl,
                headers: {
                  Authorization: this.$store.state.user.Token
                },
                customConfig: {},
                props: {
                  url: 'path',
                  res: 'data',
                  name: 'path'
                }
              },
              image: '',
              imageUrl: '',
              videoUrl: ''
            }
        },
        watch:{
            'form.content'(value){
                console.log(value)
                const image = this.findObject(this.option.column, 'image');
                const name = this.findObject(this.option.column, 'name');
                if(value == 1){
                    name.placeholder = '一句话文字介绍';
                    image.action = actionUrl;
                    image.accept = 'image/*';
                    image.tip = '支持JPG、PNG，建议上传尺寸350px*276px';
                    if(this.image == ''){
                        this.form.image = this.imageUrl;
                    }else{
                        this.form.image = this.image
                        this.image = '';
                    }
                }else{
                    name.placeholder = '视频名称(前端不显示)';
                    image.action = videoUrl;
                    image.accept = 'video/*';
                    image.tip = '视频尽量不超过30s';
                    if(this.image == ''){
                        this.form.image = this.videoUrl;
                    }else{
                        this.form.image = this.image
                        this.image = '';
                    }

                }
            }
        },
        mounted() {
            const image = this.findObject(this.option.column, 'image');
            image.headers.Authorization = this.$store.state.user.Token;
            const name = this.findObject(this.option.column, 'name');
            name.placeholder = '一句话文字介绍';
            image.action = actionUrl;
            image.accept = 'image/*';
            image.tip = '支持JPG、PNG，建议上传尺寸350px*276px';
            this.form.image = '';
            this.image = '';
            if(this.$route.params.id != 0){
                this.GET_memberView();
            }
        },
        methods: {
            salaryChange(e,t){
                this.form[t] = e.target.value;
            },
            handleSubmit(form){
                // console.log(form)
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                        if(this.$route.params.id == 0){
                            this.GET_memberAdd(form,done);
                        }else{
                            this.GET_memberUpdate(form,done);
                        }
                    }
                })
            },
            GET_memberAdd(form,done){
              enterpriseImageAdd({
                contentLocation: form.contentLocation,
                content: form.content,
                name: form.name,
                image: form.image,
                sort: form.sort,
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            GET_memberUpdate(form,done){
                enterpriseImageUpdate({
                    id: form.id,
                  contentLocation: form.contentLocation,
                  content: form.content,
                  name: form.name,
                  image: form.image,
                  sort: form.sort,
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            uploadBefore(file, done) {
              done()
              const content = this.findObject(this.option.column, 'content');
              content.disabled = true;
            },
            uploadAfter(res, done) {
                done();

                const content = this.findObject(this.option.column, 'content');
                content.disabled = false;
                this.$nextTick(() => {
                    if(this.form.content == 1){
                      if(this.imageUrl == ''){
                        this.imageUrl = res.path;
                      }else{
                        this.imageUrl = `${this.imageUrl},${res.path}`
                      }
                      this.form.image = this.imageUrl;
                    }else{
                        this.videoUrl = res.path;
                        this.form.image = this.videoUrl;
                    }
                });
            },
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadError(error, column) {
                this.$message.error(error || '文件过大');
                // console.log(error, column)
                const content = this.findObject(this.option.column, 'content');
                content.disabled = false;
            },
            GET_memberView(){
                enterpriseImageView(this.$route.params.id).then(res=>{
                    // console.log(res)
                    if(res.code===200){
                        this.form = res.data;
                        if(res.data.content == 1){
                            this.image = '';
                            this.imageUrl = res.data.image;
                        }else{
                            this.image = res.data.image;
                            this.videoUrl = res.data.image;
                        }
                        console.log('this.image',this.image)
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            checkNumber(value,key){
                if(value.length == 1){
                    this[key] = this[key].replace(/[^1-9]/g,'')
                }else{
                    this[key] = this[key].replace(/\D/g,'')
                }
            }
        }
    }
</script>

<style scoped>

</style>
