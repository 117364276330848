import { Delete, get, post, put } from '../axios'

// 企业荣誉-列表
export const enterpriseHonorList = params => get(`/web/enterprise/honor/list`,params);

// 企业荣誉-新增
export const enterpriseHonorAdd = params => post(`/web/enterprise/honor/add`,params);

// 企业荣誉-删除
export const enterpriseHonorDelete = params => Delete(`/web/enterprise/honor/delete/${params}`);

// 企业荣誉-修改
export const enterpriseHonorUpdate = params => put(`/web/enterprise/honor/update`,params);

// 企业荣誉-查看
export const enterpriseHonorView = params => get(`/web/enterprise/honor/view/${params}`);

