<template>
    <div class="main-content">
      <pagesHeader :title="`${id == 0 ? '新建':'编辑'}荣誉`" />
        <avue-form ref="form" v-model="form" :option="option" :upload-error="uploadError" :upload-delete="uploadDelete"
                   :upload-after="uploadAfter">
          <template slot="details">
            <avue-ueditor v-model="form.details" :options="uOptions"></avue-ueditor>
          </template>
            <template slot="menuForm">
                <div class="submit-button">
                  <el-button type="primary" size="medium" @click="handleSubmit(form)">确定</el-button>
                  <el-button size="medium" @click="$router.go(-1)">取消</el-button>
                </div>
            </template>
        </avue-form>
    </div>
</template>

<script>
    import column from "./column"
    import { enterpriseHonorAdd,enterpriseHonorView,enterpriseHonorUpdate } from "@/api/enterprise/manage"
    import {actionUrl} from "@/config/public";
    export default {
        name: "increase",
        data(){
            return{
                id: this.$route.params.id || 0,
                form: {},
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth: 120,
                    column: column.increaseColumn
                },
              uOptions: {
                action: actionUrl,
                headers: {
                  Authorization: this.$store.state.user.Token
                },
                customConfig: {},
                props: {
                  url: 'path',
                  res: 'data',
                  name: 'path'
                }
              },
            }
        },
        mounted() {
            const image = this.findObject(this.option.column, 'image');
            image.headers.Authorization = this.$store.state.user.Token;
            if(this.$route.params.id != 0){
                this.GET_memberView();
            }
        },
        methods: {
            salaryChange(e,t){
                this.form[t] = e.target.value;
            },
            handleSubmit(form){
                // console.log(form)
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                        if(this.$route.params.id == 0){
                            this.GET_memberAdd(form,done);
                        }else{
                            this.GET_memberUpdate(form,done);
                        }
                    }
                })
            },
            GET_memberAdd(form,done){
              enterpriseHonorAdd({
                title: form.title,
                sort: form.sort,
                image: form.image,
                honorProfile: form.honorProfile,
                details: form.details,
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            GET_memberUpdate(form,done){
                enterpriseHonorUpdate({
                    id: form.id,
                    title: form.title,
                    sort: form.sort,
                    image: form.image,
                    honorProfile: form.honorProfile,
                    details: form.details,
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            uploadAfter(res, done) {
                done();
                this.$nextTick(() => {
                    this.form.image = res.path;
                });
            },
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadError(error, column) {
                this.$message.error(error);
                // console.log(error, column)
            },
            GET_memberView(){
                enterpriseHonorView(this.$route.params.id).then(res=>{
                    // console.log(res)
                    if(res.code===200){
                        this.form = res.data;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            checkNumber(value,key){
                if(value.length == 1){
                    this[key] = this[key].replace(/[^1-9]/g,'')
                }else{
                    this[key] = this[key].replace(/\D/g,'')
                }
            }
        }
    }
</script>

<style scoped>

</style>
