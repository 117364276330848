import { imageUrl, actionUrl } from '@/config/public';
const manage = {
    increaseColumn: [
        {
            label: '企业全称',
            prop: 'fullName',
            span: 15,
            rules: [{
                required: true,
                message: '请输入企业全称',
                trigger: 'blur'
            }]
        },
        {
            label: '企业简称',
            prop: 'abbreviation',
            maxlength:10,
            placeholder:'10字以内',
            span: 15
        },
        {
            label: '企业logo',
            prop: 'logo',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/*',
            loadText: '附件上传中，请稍等',
            span: 24,
            fileSize: 1024 * 20,
            tip: '支持JPG、PNG，建议上传尺寸78px*76px',
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path',
                home: imageUrl
            },
            headers: {
                Authorization: ''
            },
            action: actionUrl,
            rules: [{
                required: true,
                message: '请上传企业logo',
                trigger: 'blur'
            }]
        },
        {
            label: '微信二维码',
            prop: 'wechatCode',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/*',
            loadText: '附件上传中，请稍等',
            span: 24,
            fileSize: 1024 * 20,
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path',
                home: imageUrl
            },
            headers: {
                Authorization: ''
            },
            action: actionUrl,
            rules: [{
                required: true,
                message: '请上传微信二维码',
                trigger: 'blur'
            }]
        },
        {
            label: '手机号码',
            prop: 'phone',
            span: 15,
            rules: [{
                required: true,
                message: '请输入手机号码',
                trigger: 'blur'
            }]
        },
        {
            label: '座机号码',
            prop: 'landline',
            maxlength:20,
            placeholder:'20字以内',
            span: 15
        },
        {
            label: '邮箱',
            prop: 'mailbox',
            span: 15,
            maxlength:30,
            placeholder:'30字以内，不限制特殊符号',
            rules: [{
                required: true,
                message: '请输入邮箱',
                trigger: 'blur'
            }]
        },
        {
            label: '微信号',
            prop: 'wechatNumber',
            span: 15,
            maxlength:30,
            placeholder:'30字以内，不限制特殊符号',
            rules: [{
                required: true,
                message: '请输入微信号',
                trigger: 'blur'
            }]
        },
        {
            label:'企业地址',
            prop:'region',
            span: 15,
            rules: [{
                required: true,
                message: '请选择企业地址',
                trigger: 'blur'
            }]
        },
        {
            label: '',
            prop: 'addressDetail',
            span: 15,
            placeholder:'详细地址',
            rules: [{
                required: true,
                message: '请输入详细地址',
                trigger: 'blur'
            }]
        },
        {
            label: '企业介绍',
            span: 24,
            prop: 'enterpriseIntroduce',
            slot: true,
            rules: [{
                required: true,
                message: '请输入企业介绍',
                trigger: 'blur'
            }]
        }
    ]
}
export default manage
