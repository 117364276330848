<template>
    <div class="main-content">
        <pagesHeader title="商品管理" />
        <Search  @changeSearch="changeSearch" @clickIncrease="clickIncrease"/>
        <avue-crud ref="crud"
                   :data="data"
                   :option="option"
                   :page.sync="pageSync"
                   @on-load="onLoad">
          <template slot="classifyName" slot-scope="{ row }">
            <div>{{row.oneClassifyName}}{{row.twoClassifyName ? `/${row.twoClassifyName}` : ''}}</div>
          </template>
          <template slot="isHot" slot-scope="{ row }">
            <el-switch v-model="row.isHotType" @change="switchChangeHot(row.id,row.isHotType)"></el-switch>
          </template>
            <template slot="timeLimit" slot-scope="{ row }"><div>{{row.timeLimit || '——'}}</div></template>
            <template slot="timeLimit" slot-scope="{ row }"><div>
                <span v-if="row.timeLimit > 0">{{row.timeLimit}}</span>
                <span v-if="row.timeLimit > 0">分钟</span>
            </div></template>
            <template slot="sort" slot-scope="{ row }"><div>{{row.sort || ''}}</div></template>
            <template slot="state" slot-scope="{ row }">
                <el-switch v-model="row.stateType" @change="switchChange(row.id,row.stateType)"></el-switch>
            </template>
            <template slot="menu" slot-scope="{ row }">
                <el-button size="small" type="text" @click="changeEdit(row.id)">编辑</el-button>
                <el-button size="small" type="text" @click="changeDelete(row.id)">删除</el-button>
            </template>
        </avue-crud>
        <Sort v-if="sort.type" :sort="sort"/>
    </div>
</template>

<script>
    import Search from "./search"
    import column from "./column";
    import {goodsList, goodsStateUpdate, goodsDelete, goodsHotUpdate} from "@/api/goods/manage"
    export default {
        name: "manage",
        components: {
            Search
        },
        data(){
            return{
                data: [],
                option: {
                    align: 'center',
                    menuAlign: 'center',
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    viewBtn: false,
                    header: false,
                    border: true,
                    column: column.column
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,pager,prev, next',
                    background: false,
                    pageSize: 10
                },
                search: {},
                sort: {
                    type: false,
                    sort: 0,
                    id: ''
                }
            }
        },
        mounted() {
            this.$root.eventHub.$on('changeGoodsSort', (n)=>{
                this.sort = {
                    type: false,
                    sort: 0,
                    id: ''
                }
                if(n){
                    this.GET_goodsList();
                }
            })
        },
        methods: {
            changeEdit(id){
                // console.log('编辑',id)
                this.$router.push({name: 'goodsIncrease', params: {id:id}})
            },
            changeDelete(id){
                // console.log('删除',id)
                this.$confirm('删除商品!, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    goodsDelete(id).then(res=>{
                        if(res.code === 200){
                            this.$message.success(res.msg)
                            this.GET_goodsList();
                        }else{
                            this.$message.error(res.msg)
                        }
                    }).catch(err=>{
                        this.$message.error(err.msg)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除!')
                });
            },
            onLoad(){
                this.GET_goodsList();
            },
            GET_goodsList(){
                goodsList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    name: this.search.name, //礼品编号/礼品名称
                  oneClassifyId: this.search.oneClassifyId,
                  twoClassifyId: this.search.twoClassifyId,
                }).then(res=>{
                    if(res.code===200){
                        res.data.records.forEach(item=>{
                            item.stateType = item.state == 1 ? true : false
                            item.isHotType = item.isHot == 1 ? true : false
                        })
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            switchChange(id,e){
                // console.log(id,e)
                goodsStateUpdate({
                    id: id,
                    state: e ? 1 : 2
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.GET_goodsList();
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
          switchChangeHot(id,e){
            // console.log(id,e)
            goodsHotUpdate({
              id: id,
              isHot: e ? 1 : 2
            }).then(res=>{
              if(res.code === 200){
                this.$message.success(res.msg)
                this.GET_goodsList();
              }else{
                this.$message.error(res.msg)
                this.GET_goodsList();
              }
            }).catch(err=>{
              this.$message.error(err.msg)
            })
          },
            clickIncrease(){
                // console.log('新增')
                this.$router.push({name: 'goodsIncrease', params: {id:0}})
            },
            changeSearch(e){
                // console.log(e)
                this.search = e;this.pageSync.currentPage = 1;
                this.GET_goodsList();
            }
        }
    }
</script>

<style scoped>

</style>
