import { Delete, get, post, put } from '../axios'

// 首页banner配置-列表
export const advertBannerList = params => get(`/web/advert/banner/list`,params);

// 首页banner配置-新增
export const advertBannerAdd = params => post(`/web/advert/banner/add`,params);

// 首页banner配置-删除
export const advertBannerDelete = params => Delete(`/web/advert/banner/delete/${params}`);

// 首页banner配置-修改
export const advertBannerUpdate = params => put(`/web/advert/banner/update`,params);

// 首页banner配置-查看
export const advertBannerView = params => get(`/web/advert/banner/view/${params}`);

// 首页banner配置-列表-资讯列表
export const advertBannerInfoList = params => get(`/web/advert/banner/list/info`,params);

// 首页banner配置-列表-商品列表
export const advertBannerGoodsList = params => get(`/web/advert/banner/list/goods`,params);

