import { Delete, get, post, put } from '../axios'

// 资讯分类管理-列表
export const infoClassifyList = params => get(`/web/info/classify/list`,params);

// 资讯分类管理-新增
export const infoClassifyAdd = params => post(`/web/info/classify/add`,params);

// 资讯分类管理-查看
export const infoClassifyView = params => get(`/web/info/classify/view/${params}`);

// 资讯分类管理-修改
export const infoClassifyUpdate = params => put(`/web/info/classify/update`,params);

// 资讯分类管理-删除
export const infoClassifyDelete = params => Delete(`/web/info/classify/delete/${params}`);

