<template>
    <div class="main-content">
      <pagesHeader :title="`${id == 0 ? '新建':'编辑'}`" />
        <avue-form ref="form" v-model="form" :option="option" :upload-error="uploadError" :upload-delete="uploadDelete"
                   :upload-after="uploadAfter">
          <template slot="phone">
            <el-input type="tel" maxlength="11" style="height:32px" placeholder="手机格式" v-model="form.phone" clearable
                      onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                      @blur="salaryChange"></el-input>
          </template>
          <template slot="region">
            <div style="display: flex">
              <el-select v-model="form.provinceId" placeholder="选择省">
                <el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
              <el-select v-model="form.cityId" placeholder="选择市">
                <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
              <el-select v-model="form.countyId" placeholder="选择区">
                <el-option v-for="item in county" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </template>
            <template slot="menuForm">
                <div class="submit-button">
                  <el-button type="primary" size="medium" @click="handleSubmit(form)">确定</el-button>
                  <el-button size="medium" @click="$router.go(-1)">取消</el-button>
                </div>
            </template>
        </avue-form>
    </div>
</template>

<script>
    import column from "./column"
    import { memberWhiteAdd,memberWhiteView,memberWhiteUpdate } from "@/api/member/manage"
    import {GetArea} from "@/api";
    export default {
        name: "increase",
        data(){
            return{
                id: this.$route.params.id || 0,
                form: {},
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth: 120,
                    column: column.increaseColumn
                },
                province: [],
                city: [],
                county: [],
                provinceId: '',
                cityId: '',
            }
        },
      watch: {
        'form.provinceId'(value){
          if(value != ''){
            this.province.forEach(item=>{
              if(item.id === value){
                this.form.province = item.name
              }
            })
            if(this.provinceId != value){
              if(this.form.cityId != '' && this.form.cityId != undefined){
                this.form.cityId = '';
                this.form.city = '';
                if(this.form.countyId != '' && this.form.cityId != undefined){
                  this.form.countyId = '';
                  this.form.county = '';
                  this.form.region = '';
                }
              }
              this.provinceId = value;
            }
            this.GET_GetArea(value,'city');
          }
        },
        'form.cityId'(value){
          if(value != ''){
            this.city.forEach(item=>{
              if(item.id === value){
                this.form.city = item.name
              }
            })
            if(this.cityId != value){
                if(this.form.countyId != '' && this.form.countyId != undefined){
                  this.form.countyId = '';
                  this.form.county = '';
                  this.form.region = '';
                }
              this.cityId = value;
            }
            this.GET_GetArea(value,'county');
          }
        },
        'form.countyId'(value){
          if(value != ''){
            this.county.forEach(item=>{
              if(item.id === value){
                this.form.county = item.name;
                this.form.region = item.name;
              }
            })
          }
        }
      },
        mounted() {
          this.GET_GetArea('0','province');
            const photo = this.findObject(this.option.column, 'photo');
          photo.headers.Authorization = this.$store.state.user.Token;
            if(this.$route.params.id != 0){
                this.GET_memberView();
            }
        },
        methods: {
            salaryChange(e,t){
                this.form[t] = e.target.value;
            },
            handleSubmit(form){
                // console.log(form)
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                        if(this.$route.params.id == 0){
                            this.GET_memberAdd(form,done);
                        }else{
                            this.GET_memberUpdate(form,done);
                        }
                    }
                })
            },
            GET_memberAdd(form,done){
              memberWhiteAdd({
                username: form.username,
                phone: form.phone,
                wechatNumber: form.wechatNumber,
                mailbox: form.mailbox,
                photo: form.photo,
                companyName: form.companyName,
                companyPosition: form.companyPosition,
                province: form.province, //门店省份,
                provinceId: form.provinceId, //门店省份Id
                city: form.city, //门店城市
                cityId: form.cityId, //门店城市Id
                county: form.county, //门店区县
                countyId: form.countyId, //门店区县Id
                addressDetail: form.addressDetail,
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            GET_memberUpdate(form,done){
                memberWhiteUpdate({
                    id: form.id,
                  username: form.username,
                  phone: form.phone,
                  wechatNumber: form.wechatNumber,
                  mailbox: form.mailbox,
                  photo: form.photo,
                  companyName: form.companyName,
                  companyPosition: form.companyPosition,
                  province: form.province, //门店省份,
                  provinceId: form.provinceId, //门店省份Id
                  city: form.city, //门店城市
                  cityId: form.cityId, //门店城市Id
                  county: form.county, //门店区县
                  countyId: form.countyId, //门店区县Id
                  addressDetail: form.addressDetail,
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
          GET_GetArea(e,type){
            GetArea(e).then(res=>{
              // console.log(res)
              if(res.code === 200){
                if(type === 'province'){
                  this.province = res.data;
                }else if(type === 'city'){
                  this.city = res.data;
                }else{
                  this.county = res.data;
                }
              }else{
                this.$message.error(res.msg)
              }
            }).catch(err=>{
              this.$message.error(err.msg)
            })
          },
            uploadAfter(res, done) {
                done();
                this.$nextTick(() => {
                    this.form.photo = res.path;
                });
            },
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadError(error, column) {
                this.$message.error(error);
                // console.log(error, column)
            },
            GET_memberView(){
              memberWhiteView(this.$route.params.id).then(res=>{
                    // console.log(res)
                    if(res.code===200){
                        this.form = res.data;

                        this.provinceId = res.data.provinceId;
                        this.cityId = res.data.cityId;
                      this.form.region = res.data.county;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            checkNumber(value,key){
                if(value.length == 1){
                    this[key] = this[key].replace(/[^1-9]/g,'')
                }else{
                    this[key] = this[key].replace(/\D/g,'')
                }
            }
        }
    }
</script>

<style scoped>

</style>
