import {Delete, get, post, put} from "../axios";

// 商品分类管理-列表
export const classifyList = params => get(`/web/goods/classify/list`,params);

// 商品分类管理-新增
export const classifyAdd = params => post(`/web/goods/classify/add`,params);

// 商品分类管理-删除
export const classifyDelete = params => Delete(`/web/goods/classify/delete/${params}`);

// 商品分类管理-查看
export const classifyView = params => get(`/web/goods/classify/view/${params}`);

// 商品分类管理-修改
export const classifyUpdate = params => put(`/web/goods/classify/update`,params);
