import { Delete, get, post, put } from '../axios'

// 图文视频-列表
export const enterpriseImageList = params => get(`/web/enterprise/image/list`,params);

// 图文视频-新增
export const enterpriseImageAdd = params => post(`/web/enterprise/image/add`,params);

// 图文视频-删除
export const enterpriseImageDelete = params => Delete(`/web/enterprise/image/delete/${params}`);

// 图文视频-修改
export const enterpriseImageUpdate = params => put(`/web/enterprise/image/update`,params);

// 图文视频-查看
export const enterpriseImageView = params => get(`/web/enterprise/image/view/${params}`);

