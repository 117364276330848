import {get, post, Delete, put} from '../axios'

// 积分商品-列表
export const goodsList = params => get(`/web/goods/manage/list`,params);

// 积分商品-新增
export const goodsAdd = params => post(`/web/goods/manage/add`,params);

// 积分商品-删除
export const goodsDelete = params => Delete(`/web/goods/manage/delete/${params}`);

// 积分商品-查看
export const goodsView = params => get(`/web/goods/manage/view/${params}`);

// 积分商品-修改
export const goodsUpdate = params => put(`/web/goods/manage/update`,params);

// 商品信息管理-修改-显示状态
export const goodsStateUpdate = params => put(`/web/goods/manage/state/update`,params);

// 商品信息管理-修改-热销标签
export const goodsHotUpdate = params => put(`/web/goods/manage/hot/update`,params);

// 分类管理-列表
export const classifyList = params => get(`/web/goods/manage/list/classify`,params);

