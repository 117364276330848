<template>
    <div class="main-content">
        <pagesHeader title="会员信息" />
        <Search @changeSearch="changeSearch"/>
        <avue-crud ref="crud"
                   :data="data"
                   :option="option"
                   :page.sync="pageSync"
                   @on-load="onLoad">
          <template slot="avatarUrl" slot-scope="{ row }">
            <img v-if="row.avatarUrl" style="width: 39px;height: 39px;display: block;margin: 0 auto" :src="row.avatarUrl" alt="">
            <img v-else style="width: 39px;height: 39px;display: block;margin: 0 auto" src="../../../style/image/user.png" alt="">
          </template>
        </avue-crud>
    </div>
</template>

<script>
    import column from "./column";
    import { memberManageList } from "@/api/member/member"
    import Search from "./search"
    export default {
        name: "level",
        components: {
            Search
        },
        data(){
            return{
                data: [],
                option: {
                    align: 'center',
                    menuAlign: 'center',
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    viewBtn: false,
                    header: false,
                    border: true,
                    menuWidth: 200,
                    index: false,
                    indexWidth: 100,
                    menu:false,
                    column: column.column
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,pager,prev, next',
                    background: false,
                    pageSize: 10
                },
                search: {},
            }
        },
        methods: {
            onLoad(){
                this.GET_memberList();
            },
            GET_memberList(){
              memberManageList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    nickname: this.search.nickname
                }).then(res=>{
                    // console.log(res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            changeEdit(id){
                // console.log('编辑',id)
                this.$router.push({ name: 'memberMemberIncrease', params: { id: id } });
            },
            changeSearch(e){
                // console.log(e)
                this.search = e;this.pageSync.currentPage = 1;
                this.GET_memberList();
            }
        }
    }
</script>

<style scoped>

</style>
