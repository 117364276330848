import {
    imageUrl,
    actionUrl,
    videoUrl
} from '@/config/public';
const column = {
    column: [
        {
            label: 'ID',
            prop: 'id'
        },
        {
            label: '资讯标题',
            prop: 'title'
        },
        {
            label: '主图',
            prop: 'mainImage',
            type: 'img'
        },
        {
            label: '所属分类',
            prop: 'classifyName'
        },
        {
            label: '排序',
            prop: 'sort'
        },
        {
            label: '创建时间',
            prop: 'createTime'
        }
    ],
    increaseColumn: [
        {
            label: '资讯标题',
            prop: 'title',
            span: 15,
            placeholder:'不限制字数',
            rules: [{
                required: true,
                message: '请输入资讯标题',
                trigger: 'blur'
            }]
        },
        {
            label: '资讯分类',
            prop: 'classifyId',
            type: 'select',
            dicData: [],
            props: {
                label: "name",
                value: "id"
            },
            span: 15,
            row: true,
            rules: [{
                required: true,
                message: "选择资讯分类",
                trigger: "blur"
            }],
        },
        {
            label: '排序',
            prop: 'sort',
            span: 15,
            type: 'number',
            controls:false,
            minRows: 0,
            maxRows: 99999999,
            placeholder:'数字越大越靠前',
            row: true,
            rules: [{
                required: true,
                message: '请输入排序',
                trigger: 'blur'
            }]
        },
        {
            label: '主图',
            prop: 'mainImage',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/*',
            loadText: '附件上传中，请稍等',
            tip: '支持JPG、PNG，建议上传尺寸182px*182px',
            span: 24,
            fileSize: 1024 * 20,
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path',
                home: imageUrl
            },
            headers: {
                Authorization: ''
            },
            action: actionUrl,
            rules: [{
                required: true,
                message: '请上传主图',
                trigger: 'blur'
            }]
        },
        {
            label: '视频',
            prop: 'video',
            type: 'upload',
            listType: 'picture-img',
            accept: 'video/mp4',
            loadText: '附件上传中，请稍等',
            tip: '视频尽量不超过30s',
            span: 24,
            fileSize: 1024 * 20,
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path',
                home: imageUrl
            },
            headers: {
                Authorization: ''
            },
            action: videoUrl
        },
        {
            label: '跳转链接',
            prop: 'jumpLink',
            span: 15,
            placeholder:'可跳转至微信公众号文章中'
        },
        {
            label: '内容',
            span: 20,
            prop: 'content',
            slot: true,
            rules: [{
                required: true,
                message: '请输入内容',
                trigger: 'blur'
            }]
        }
    ]
}
export default column
