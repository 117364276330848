import { Delete, get, post, put } from '../axios'

// 白名单用户-列表
export const memberWhiteList = params => get(`/web/member/white/list`,params);

// 白名单用户-新增
export const memberWhiteAdd = params => post(`/web/member/white/add`,params);

// 白名单用户-删除
export const memberWhiteDelete = params => Delete(`/web/member/white/delete/${params}`);

// 白名单用户-修改
export const memberWhiteUpdate = params => put(`/web/member/white/update`,params);

// 白名单用户-查看
export const memberWhiteView = params => get(`/web/member/white/view/${params}`);

