var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.length > 0 && _vm.show)?_c('avue-crud',{ref:"crud",attrs:{"data":_vm.data,"option":_vm.option},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"upload"},[_c('input',{staticClass:"uploadImage",attrs:{"accept":"image/*","type":"file"},on:{"change":function($event){return _vm.tirggerFile($event,index)}}}),(row.image)?_c('img',{attrs:{"src":row.image,"alt":""}}):_c('p',[_vm._v("上传图片")])])]}},{key:"skuCode",fn:function(ref){
var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.skuCode),expression:"row.skuCode"}],attrs:{"type":"text","placeholder":"唯一,不能重复","maxlength":"10","placeholder-style":"color: rgb(153, 153, 153)"},domProps:{"value":(row.skuCode)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(row, "skuCode", $event.target.value)},_vm.priceInput]}})]}},{key:"skuName",fn:function(ref){
var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.skuName),expression:"row.skuName"}],attrs:{"type":"text","maxlength":"10"},domProps:{"value":(row.skuName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(row, "skuName", $event.target.value)},_vm.priceInput]}})]}},{key:"points",fn:function(ref){
var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.points),expression:"row.points"}],attrs:{"type":"text","min":"1","pattern":"[0-9]","maxlength":"6","onkeyup":"this.value=this.value.replace(/[\\W]/g,'')","onbeforepaste":"clipboardData.setData('text',clipboardData.getData('text').replace(/[^\\d]/g,''))"},domProps:{"value":(row.points)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(row, "points", $event.target.value)},_vm.priceInput],"blur":_vm.salaryChange1}})]}},{key:"stock",fn:function(ref){
var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.stock),expression:"row.stock"}],attrs:{"type":"text","min":"1","pattern":"[0-9]","maxlength":"6","onkeyup":"this.value=this.value.replace(/[\\W]/g,'')","onbeforepaste":"clipboardData.setData('text',clipboardData.getData('text').replace(/[^\\d]/g,''))"},domProps:{"value":(row.stock)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(row, "stock", $event.target.value)},_vm.priceInput],"blur":_vm.salaryChange2}})]}}],null,false,3827880317)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }