import {
    imageUrl,
    actionUrl
} from '@/config/public';
const column = {
    column: [
        {
            label: 'ID',
            prop: 'id'
        },
        {
            label: '名称',
            prop: 'name'
        },
        {
            label: '图片/视频',
            prop: 'image',
            type: 'img'
        },
        {
            label: '内容位置',
            prop: 'contentLocation',
            type: 'radio',
            dicData: [{
                label: '团队风采',
                value: 1
            }, {
                label: '企业活动',
                value: 2
            }]
        },
        {
            label: '排序',
            prop: 'sort'
        },
        {
            label: '创建时间',
            prop: 'createTime'
        }
    ],
    increaseColumn: [
        {
            label:'内容位置',
            prop:'contentLocation',
            type: 'radio',
            dicData: [
                {
                    label: '团队风采',
                    value: 1
                },
                {
                    label: '企业活动',
                    value: 2
                }
            ],
            span: 24,
            rules: [{
                required: true,
                message: '请输入内容位置',
                trigger: 'blur'
            }]
        },
        {
            label:'内容',
            prop:'content',
            type: 'radio',
            dicData: [
                {
                    label: '图片',
                    value: 1
                },
                {
                    label: '视频',
                    value: 2
                }
            ],
            span: 24,
            rules: [{
                required: true,
                message: '请输入内容位置',
                trigger: 'blur'
            }],
            disabled: false
        },
        {
            label: '',
            prop: 'name',
            span: 15,
            placeholder:'一句话文字介绍',
            rules: [{
                required: true,
                message: '请输入内容',
                trigger: 'blur'
            }]
        },
        {
            label: '',
            prop: 'image',
            type: 'upload',
            listType: 'picture-card',
            accept: 'image/*',
            loadText: '附件上传中，请稍等',
            tip: '支持JPG、PNG',
            limit: 5,
            fileSize: 1024 * 50,
            span: 24,
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path',
                home: imageUrl
            },
            headers: {
                Authorization: ''
            },
            action: actionUrl,
            rules: [{
                required: true,
                message: '请上传图片',
                trigger: 'blur'
            }]
        },
        {
            label: '排序',
            prop: 'sort',
            span: 15,
            type: 'number',
            controls:false,
            minRows: 0,
            maxRows: 99999999,
            placeholder:'数字越大越靠前',
            row: true,
            rules: [{
                required: true,
                message: '请输入排序',
                trigger: 'blur'
            }]
        }
    ]
}
export default column
