import {
    imageUrl,
    actionUrl
} from '@/config/public';
const column = {
    column: [
        {
            label: 'ID',
            prop: 'id'
        },
        {
            label: '用户姓名',
            prop: 'username'
        },
        {
            label: '用户手机号码',
            prop: 'phone'
        },
        {
            label: '公司名称',
            prop: 'companyName'
        },
        {
            label: '公司职位',
            prop: 'companyPosition'
        },
        {
            label: '公司地址',
            prop: 'companyAddress',
            slot: true
        },
        {
            label: '照片',
            prop: 'photo',
            type: 'img'
        },
        {
            label: 'openID',
            prop: 'openId'
        }
    ],
    increaseColumn: [
        {
            label: '用户姓名',
            prop: 'username',
            span: 15,
            maxlength: 10,
            showWordLimit:true,
            placeholder:'10字以内',
            rules: [{
                required: true,
                message: '请输入用户姓名',
                trigger: 'blur'
            }]
        },
        {
            label: '手机号码',
            prop: 'phone',
            span: 15,
            rules: [{
                required: true,
                message: '请输入手机号码',
                trigger: 'blur'
            }]
        },
        {
            label: '微信号',
            prop: 'wechatNumber',
            span: 15,
            maxlength:20,
            showWordLimit:true,
            placeholder:'20字以内，不限制特殊符号',
            rules: [{
                required: true,
                message: '请输入微信号',
                trigger: 'blur'
            }]
        },
        {
            label: '邮箱',
            prop: 'mailbox',
            span: 15,
            maxlength:30,
            showWordLimit:true,
            placeholder:'30字以内，不限制特殊符号',
            rules: [{
                required: true,
                message: '请输入邮箱',
                trigger: 'blur'
            }]
        },
        {
            label: '照片',
            prop: 'photo',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/*',
            loadText: '附件上传中，请稍等',
            tip: '支持JPG、PNG，建议上传尺寸156px*156px',
            fileSize: 1024 * 20,
            span: 24,
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path',
                home: imageUrl
            },
            headers: {
                Authorization: ''
            },
            action: actionUrl
        },
        {
            label: '公司名称',
            prop: 'companyName',
            span: 15,
            placeholder:'不限制字数',
            rules: [{
                required: true,
                message: '请输入公司名称',
                trigger: 'blur'
            }]
        },
        {
            label: '公司职位',
            prop: 'companyPosition',
            span: 15,
            maxlength: 20,
            showWordLimit:true,
            placeholder:'20字以内',
            rules: [{
                required: true,
                message: '请输入公司职位',
                trigger: 'blur'
            }]
        },
        {
            label:'企业地址',
            prop:'region',
            span: 15,
            rules: [{
                required: true,
                message: '请输入企业地址',
                trigger: 'blur'
            }]
        },
        {
            label: '',
            prop: 'addressDetail',
            span: 15,
            placeholder:'详细地址（不限制字数）',
            rules: [{
                required: true,
                message: '请输入详细地址',
                trigger: 'blur'
            }]
        }
    ]
}
export default column
