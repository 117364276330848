var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('pagesHeader',{attrs:{"title":"商品管理"}}),_c('Search',{on:{"changeSearch":_vm.changeSearch,"clickIncrease":_vm.clickIncrease}}),_c('avue-crud',{ref:"crud",attrs:{"data":_vm.data,"option":_vm.option,"page":_vm.pageSync},on:{"update:page":function($event){_vm.pageSync=$event},"on-load":_vm.onLoad},scopedSlots:_vm._u([{key:"classifyName",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.oneClassifyName)+_vm._s(row.twoClassifyName ? ("/" + (row.twoClassifyName)) : ''))])]}},{key:"isHot",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{on:{"change":function($event){return _vm.switchChangeHot(row.id,row.isHotType)}},model:{value:(row.isHotType),callback:function ($$v) {_vm.$set(row, "isHotType", $$v)},expression:"row.isHotType"}})]}},{key:"timeLimit",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.timeLimit > 0)?_c('span',[_vm._v(_vm._s(row.timeLimit))]):_vm._e(),(row.timeLimit > 0)?_c('span',[_vm._v("分钟")]):_vm._e()])]}},{key:"sort",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.sort || ''))])]}},{key:"state",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{on:{"change":function($event){return _vm.switchChange(row.id,row.stateType)}},model:{value:(row.stateType),callback:function ($$v) {_vm.$set(row, "stateType", $$v)},expression:"row.stateType"}})]}},{key:"menu",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.changeEdit(row.id)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.changeDelete(row.id)}}},[_vm._v("删除")])]}}])}),(_vm.sort.type)?_c('Sort',{attrs:{"sort":_vm.sort}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }