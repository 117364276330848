import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// pages
// 登录
import login from '@/pages/login/login';
// 首页
import index from '@/pages/index/index';

// view 内容
// index首页
import indexHome from '@/view/index/index';

// 企业信息管理
import enterpriseInfo from '@/view/enterprise/info/increase'
// 企业荣誉
import enterpriseHonor from '@/view/enterprise/honor/level';
import enterpriseHonorIncrease from '@/view/enterprise/honor/increase';
// 企业事件
import enterpriseEvent from '@/view/enterprise/event/level';
import enterpriseEventIncrease from '@/view/enterprise/event/increase';
// 图文视频
import enterpriseImage from '@/view/enterprise/image/level';
import enterpriseImageIncrease from '@/view/enterprise/image/increase';
// 资讯分类管理
import infoClassify from '@/view/info/classify/identity';
// 资讯管理
import infoManage from '@/view/info/info/level';
import infoManageIncrease from '@/view/info/info/increase';
// 白名单用户
import memberWhite from '@/view/member/white/level';
import memberWhiteIncrease from '@/view/member/white/increase';
// 会员信息
import memberManage from '@/view/member/manage/level';
// 首页banner配置
import advertBanner from '@/view/advert/banner/level';
import advertBannerIncrease from '@/view/advert/banner/increase';

import goodsClassify from '@/view/goods/classify/classify';
import goodsManage from '@/view/goods/manage/manage';
import goodsIncrease from '@/view/goods/manage/increase';

// system 系统管理
import systemUser from '@/view/system/user';
import systemRole from '@/view/system/role';

export default new Router({
	// mode: 'history',//去掉#号、
	mode: 'hash', //保留#号、
	routes: [
		{
			path: '/login',
			name: 'login',
			component: login,
		},
		{
			path: '/',
			component: index,
			children: [
				{
					path: '/',
					name: 'indexHome',
					component: indexHome
				},
				// ------------------------------------------------------ 系统管理
				{
					path: '/system/user',
					name: 'systemUser',
					component: systemUser
				},
				{
					path: '/system/role',
					name: 'role',
					component: systemRole
				},
				// ------------------------------------------------------ 企业信息
				{
					path: '/enterprise/info',
					name: 'enterpriseInfo',
					component: enterpriseInfo
				},
				{
					path: '/enterprise/honor',
					name: 'enterpriseHonor',
					component: enterpriseHonor
				},
				{
					path: '/enterprise/honor/increase/:id',
					name: 'enterpriseHonorIncrease',
					component: enterpriseHonorIncrease
				},
				{
					path: '/enterprise/event',
					name: 'enterpriseEvent',
					component: enterpriseEvent
				},
				{
					path: '/enterprise/event/increase/:id',
					name: 'enterpriseEventIncrease',
					component: enterpriseEventIncrease
				},
				{
					path: '/enterprise/image',
					name: 'enterpriseImage',
					component: enterpriseImage
				},
				{
					path: '/enterprise/image/increase/:id',
					name: 'enterpriseImageIncrease',
					component: enterpriseImageIncrease
				},
				{
					path: '/info/classify',
					name: 'infoClassify',
					component: infoClassify
				},
				{
					path: '/info/manage',
					name: 'infoManage',
					component: infoManage
				},
				{
					path: '/info/manage/increase/:id',
					name: 'infoManageIncrease',
					component: infoManageIncrease
				},
				{
					path: '/member/white',
					name: 'memberWhite',
					component: memberWhite
				},
				{
					path: '/member/white/increase/:id',
					name: 'memberWhiteIncrease',
					component: memberWhiteIncrease
				},
				{
					path: '/member/manage',
					name: 'memberManage',
					component: memberManage
				},
				{
					path: '/advert/banner',
					name: 'advertBanner',
					component: advertBanner
				},
				{
					path: '/advert/banner/increase/:id',
					name: 'advertBannerIncrease',
					component: advertBannerIncrease
				},
				{
					path: '/goods/classify',
					name: 'goodsClassify',
					component: goodsClassify
				},
				{
					path: '/goods/manage',
					name: 'goodsManage',
					component: goodsManage
				},
				{
					path: '/goods/increase/:id',
					name: 'goodsIncrease',
					component: goodsIncrease
				},
			]
		}
	]
})
