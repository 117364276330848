import {
    imageUrl,
    actionUrl
} from '@/config/public';
const column = {
    column: [
        {
            label: 'ID',
            prop: 'id'
        },
        {
            label: 'banner标题',
            prop: 'title'
        },
        {
            label: '图片',
            prop: 'image',
            type: 'img'
        },
        {
            label: '排序',
            prop: 'sort'
        }
    ],
    increaseColumn: [
        {
            label: 'banner标题',
            prop: 'title',
            span: 15,
            placeholder:'前端不显示',
            rules: [{
                required: true,
                message: '请输入banner标题',
                trigger: 'blur'
            }]
        },
        {
            label: '排序',
            prop: 'sort',
            span: 15,
            type: 'number',
            controls:false,
            minRows: 0,
            maxRows: 99999999,
            placeholder:'数字越大越靠前',
            row: true,
            rules: [{
                required: true,
                message: '请输入排序',
                trigger: 'blur'
            }]
        },
        {
            label: '图片',
            prop: 'image',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/*',
            loadText: '附件上传中，请稍等',
            tip: '支持JPG、PNG，建议上传尺寸710px*338px',
            fileSize: 1024 * 20,
            span: 24,
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path',
                home: imageUrl
            },
            headers: {
                Authorization: ''
            },
            action: actionUrl,
            rules: [{
                required: true,
                message: '请上传图片',
                trigger: 'blur'
            }]
        },
        {
            label: '内部跳转链接',
            span: 15,
            prop: 'jumpType',
            type: 'select',
            placeholder:'请选择',
            dicData: [{
                label: '商品',
                value: 1
            }, {
                label: '资讯',
                value: 2
            }]
        },
        {
            label: '详情',
            span: 15,
            prop: 'content',
            type: 'select',
            dicData: [],
            props: {
                label: 'name',
                value: 'id'
            },
            rules: [{
                required: false,
                message: '请选择',
                trigger: 'blur'
            }]
        }
    ]
}
export default column
