import { Delete, get, post, put } from '../axios'

// 资讯管理-列表
export const infoManageList = params => get(`/web/info/manage/list`,params);

// 资讯管理-新增
export const infoManageAdd = params => post(`/web/info/manage/add`,params);

// 资讯管理-删除
export const infoManageDelete = params => Delete(`/web/info/manage/delete/${params}`);

// 资讯管理-修改
export const infoManageUpdate = params => put(`/web/info/manage/update`,params);

// 资讯管理-查看
export const infoManageView = params => get(`/web/info/manage/view/${params}`);

// 资讯管理-列表-分类列表
export const infoManageClassify = params => get(`/web/info/manage/classify/list`,params);

