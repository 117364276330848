import { Delete, get, post, put } from '../axios'

// 企业事件-列表
export const enterpriseEventList = params => get(`/web/enterprise/event/list`,params);

// 企业事件-新增
export const enterpriseEventAdd = params => post(`/web/enterprise/event/add`,params);

// 企业事件-删除
export const enterpriseEventDelete = params => Delete(`/web/enterprise/event/delete/${params}`);

// 企业事件-修改
export const enterpriseEventUpdate = params => put(`/web/enterprise/event/update`,params);

// 企业事件-查看
export const enterpriseEventView = params => get(`/web/enterprise/event/view/${params}`);

