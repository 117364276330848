<template>
    <div class="main-content">
        <pagesHeader title="首页banner配置" />
        <Search @changeSearch="changeSearch"/>
        <avue-crud ref="crud"
                   :data="data"
                   :option="option"
                   :page.sync="pageSync"
                   @on-load="onLoad">
            <template slot="menu" slot-scope="{ row }">
                <el-button size="small" type="text" @click="changeEdit(row.id)">编辑</el-button>
                <el-button size="small" type="text" @click="changeDelete(row.id)">删除</el-button>
            </template>
        </avue-crud>
    </div>
</template>

<script>
    import column from "./column";
    import { advertBannerList,advertBannerDelete } from "@/api/advert/manage"
    import Search from "./search"
    export default {
        name: "level",
        components: {
            Search
        },
        data(){
            return{
                data: [],
                option: {
                    align: 'center',
                    menuAlign: 'center',
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    viewBtn: false,
                    header: false,
                    border: true,
                    menuWidth: 200,
                    index: false,
                    indexWidth: 100,
                    column: column.column
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,pager,prev, next',
                    background: false,
                    pageSize: 10
                },
                search: {},
            }
        },
        methods: {
            onLoad(){
                this.GET_memberList();
            },
            GET_memberList(){
              advertBannerList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    name: this.search.name
                }).then(res=>{
                    // console.log(res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            changeEdit(id){
                // console.log('编辑',id)
                this.$router.push({ name: 'advertBannerIncrease', params: { id: id } });
            },
            changeDelete(id){
                this.$confirm("是否删除?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    advertBannerDelete(id).then(res=>{
                        if(res.code === 200){
                            this.$message.success(res.msg)
                            this.GET_memberList();
                        }else{
                            this.$message.error(res.msg)
                        }
                    }).catch(err=>{
                        this.$message.error(err.msg)
                    })
                });
            },
            changeSearch(e){
                // console.log(e)
                this.search = e;this.pageSync.currentPage = 1;
                this.GET_memberList();
            }
        }
    }
</script>

<style scoped>

</style>
