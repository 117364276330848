<template>
    <div class="main-content">
      <pagesHeader :title="`${id == 0 ? '添加':'编辑'}banner`" />
        <avue-form ref="form" v-model="form" :option="option" :upload-error="uploadError" :upload-delete="uploadDelete"
                   :upload-after="uploadAfter">
            <template slot="menuForm">
                <div class="submit-button">
                  <el-button type="primary" size="medium" @click="handleSubmit(form)">确定</el-button>
                  <el-button size="medium" @click="$router.go(-1)">取消</el-button>
                </div>
            </template>
        </avue-form>
    </div>
</template>

<script>
    import column from "./column"
    import {advertBannerAdd, advertBannerView, advertBannerUpdate,advertBannerGoodsList,advertBannerInfoList} from "@/api/advert/manage"
    export default {
        name: "increase",
        data(){
            return{
                id: this.$route.params.id || 0,
                form: {
                  dicData: [],
                },
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth: 120,
                    column: column.increaseColumn
                },
                content: ''
            }
        },
        watch:{
            'form.jumpType'(value){
                const content = this.findObject(this.option.column, 'content');
                if(value == 1){
                    this.form.content = '';
                    this.get_advertBannerGoodsList();
                }else if(value == 2){
                    this.form.content = '';
                    this.get_advertBannerInfoList();
                }else{
                    content.rules[0].required = false;
                    this.form.content = '';
                }
            }
        },
        mounted() {
            const image = this.findObject(this.option.column, 'image');
            image.headers.Authorization = this.$store.state.user.Token;

            if(this.$route.params.id != 0){
                this.GET_memberView();
            }
        },
        methods: {
            get_advertBannerGoodsList(){
                const content = this.findObject(this.option.column, 'content');
                advertBannerGoodsList().then(res=>{
                    if(res.code == 200){
                        content.dicData = res.data;
                        content.rules[0].required = true;
                        if(this.$route.params.id != 0){
                            this.form.content = this.content;
                            this.content = '';

                        }
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err =>{
                    this.$message.error(err.msg)
                })
            },
            get_advertBannerInfoList(){
                const content = this.findObject(this.option.column, 'content');
                advertBannerInfoList().then(res=>{
                    if(res.code == 200){
                        content.dicData = res.data;
                        content.rules[0].required = true;
                        if(this.$route.params.id != 0){
                            this.form.content = this.content;
                            this.content = '';
                        }
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err =>{
                    this.$message.error(err.msg)
                })
            },
            salaryChange(e,t){
                this.form[t] = e.target.value;
            },
            handleSubmit(form){
                // console.log(form)
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                        if(this.$route.params.id == 0){
                            this.GET_memberAdd(form,done);
                        }else{
                            this.GET_memberUpdate(form,done);
                        }
                    }
                })
            },
            GET_memberAdd(form,done){
              advertBannerAdd({
                title: form.title,
                sort: form.sort,
                image: form.image,
                content: form.content,
                jumpType: form.jumpType,
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            GET_memberUpdate(form,done){
                advertBannerUpdate({
                    id: form.id,
                  title: form.title,
                  sort: form.sort,
                  image: form.image,
                  content: form.content,
                  jumpType: form.jumpType,
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            uploadAfter(res, done) {
                done();
                this.$nextTick(() => {
                    this.form.image = res.path;
                });
            },
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadError(error, column) {
                this.$message.error(error);
                // console.log(error, column)
            },
            GET_memberView(){
                advertBannerView(this.$route.params.id).then(res=>{
                    // console.log(res)
                    if(res.code===200){
                        this.form = res.data;
                        this.form.jumpType = res.data.jumpType === 0 ? '': res.data.jumpType;
                        this.content = res.data.content;

                        const content = this.findObject(this.option.column, 'content');
                        if(res.data.jumpType == 0){
                            content.rules[0].required = false;
                        }else{
                            content.rules[0].required = true;
                        }
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            checkNumber(value,key){
                if(value.length == 1){
                    this[key] = this[key].replace(/[^1-9]/g,'')
                }else{
                    this[key] = this[key].replace(/\D/g,'')
                }
            },

        }
    }
</script>

<style scoped>

</style>
