import {actionUrl, imageUrl, videoUrl} from '@/config/public';
const column = {
    column: [
        {
            label: '商品编码',
            prop: 'code'
        },
        {
            label: '商品分类',
            prop: 'classifyName',
            slot: true
        },
        {
            label: '商品名称',
            prop: 'name'
        },
        {
            label: '显示状态',
            prop: 'state',
            type: 'radio',
            dicData: [
                {
                    label: '是',
                    value: 1
                },
                {
                    label: '否',
                    value: 2
                }
            ]
        },
        {
            label: '热销标签',
            prop: 'isHot',
            type: 'radio',
            dicData: [
                {
                    label: '是',
                    value: 1
                },
                {
                    label: '否',
                    value: 2
                }
            ]
        },
        {
            label: '创建时间',
            prop: 'createTime'
        }
    ],
    increaseColumn: [
        {
            label: '商品编码',
            prop: 'code',
            span: 15,
        },
        {
            label: '商品名称',
            prop: 'name',
            span: 15,
            row: true,
            rules: [{
                required: true,
                message: '请输入商品名称',
                trigger: 'blur'
            }]
        },
        {
            label: '产品分类',
            prop: 'classifyStr',
            type: "cascader",
            checkStrictly: true,
            placeholder: '选择分类',
            dicData: [],
            props: {
                label: "name",
                value: "id"
            },
            span: 15,
            row: true,
            rules: [{
                required: true,
                message: "选择产品分类",
                trigger: "blur"
            }]
        },
        {
            label: '排序',
            prop: 'sort',
            span: 15,
            type: 'number',
            controls:false,
            minRows: 0,
            maxRows: 99999999,
            placeholder:'数字越大越靠前，未输入按照创建时间倒序',
            row: true
        },
        {
            label: '是否显示',
            prop: 'state',
            type: 'select',
            span: 15,
            row: true,
            dicData: [
                {
                    label: '上架',
                    value: 1,
                    select:true
                },
                {
                    label: '下架',
                    value: 2
                }
            ],
            rules: [{
                required: true,
                message: '请选择是否显示',
                trigger: 'blur'
            }]
        },
        {
            label: '商品封面',
            prop: 'cover',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/*',
            loadText: '附件上传中，请稍等',
            fileSize: 1024 * 20,
            tip: '支持JPG、PNG，建议上传尺寸340px*340px',
            // propsHttp: {
            //     res:'data',
            //     url: 'url',
            //     name: 'path'
            // },
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "请上传商品封面",
                trigger: "blur"
            }]
        },
        {
            label: '更多图片',
            prop: 'banner',
            type: 'upload',
            listType: 'picture-card',
            dataType: 'string',
            accept: 'image/*',
            fileSize: 1024 * 20,
            loadText: '附件上传中，请稍等',
            tip: '支持JPG、PNG，建议上传尺寸750px*750px',
            limit: 10,
            // propsHttp: {
            //     res:'data',
            //     url: 'url',
            //     name: 'path'
            // },
            action: actionUrl,
            headers: {
                Authorization: ''
            },
            span: 24,
            row: true
        },
        {
            label: '上传视频',
            prop: 'video',
            type: 'upload',
            listType: 'picture-img',
            accept: 'video/mp4',
            fileSize: 1024 * 20,
            loadText: '附件上传中，请稍等',
            span: 24,
            // propsHttp: {
            //     res: 'data',
            //     url: 'url',
            //     name: 'path',
            //     home: imageUrl
            // },
            headers: {
                Authorization: ''
            },
            action: videoUrl
        },
        {
            label: '',
            prop: 'hr',
            labelWidth: 0,
            slot: true,
            span: 24
        },
        {
            label: '规格',
            prop: 'spec',
            slot: true,
            span: 24,
            rules: [{
                required: true,
                message: "请填写规格",
                trigger: "blur"
            }]
        },
        {
            label: '商品详情',
            prop: 'details',
            span: 20
        }
    ]
}
export default column
