<template>
    <div class="search">
        <el-button type="primary" @click="clickIncrease" class="margin">新增商品</el-button>
      <el-cascader :options="options" v-model="value" clearable class="margin" :props="props" placeholder="商品分类" @change="changeCascader"></el-cascader>
        <el-input v-model="form.name" placeholder="输入商品编码/商品名称/SKU编码" class="margin"></el-input>
        <el-button type="primary" @click="searchQuery" style="margin-bottom: 10px">搜索</el-button>
        <el-button type="info" @click="searchReset" style="margin-bottom: 10px">重置</el-button>
    </div>
</template>

<script>
    import {classifyList} from "@/api/goods/manage"
    export default {
        name: "search",
        data(){
            return{
                form: {
                  name: '',
                  oneClassifyId: '',
                  twoClassifyId: ''
                },
              options: [],
              props: {
                checkStrictly: true,
                label: 'name',
                value: 'id'
              },
              value: []
            }
        },
      mounted() {
        this.getClassifyList();
      },
        methods: {
          getClassifyList(){
            classifyList().then(res=>{
              if(res.code == 200){
                this.options = res.data;
              }else{
                this.$message.error(res.msg)
              }
            }).catch(err=>{
              this.$message.error(err.msg)
            })
          },
          changeCascader(e){
            // console.log(e)
            if(e.length > 0){
              e.forEach((item,index)=>{
                if(index == 0 && e.length == 1){
                  this.form.oneClassifyId = item;
                  this.form.twoClassifyId = '';
                }else if(index == 1 && e.length == 2){
                  this.form.oneClassifyId = '';
                  this.form.twoClassifyId = item;
                }
              })
            }
          },
            clickIncrease(){
                // console.log('新增')
                this.$emit('clickIncrease')
            },
            searchQuery(){
                // console.log('searchQuery')
              this.form.oneClassifyId=this.value[0]?this.value[0]:''
              this.form.twoClassifyId=this.value[1]?this.value[1]:''
                this.$emit('changeSearch',this.form)
            },
            searchReset(){
                // console.log('searchReset')
              this.form = {
                productModel: '',
                oneClassifyId: '',
                twoClassifyId: '',
              }
              this.value = [];
              this.$emit('changeSearch',this.form)
            }
        }
    }
</script>

<style scoped>
    .search {
        display: flex;
    }
    .date-picker >>> .el-range-title {
        width: 20% !important;
        padding: 0 0 0 10px !important;
        font-size: 14px;
    }
    .date-picker >>> .el-range-separator {
        padding: 0;
    }
    .date-picker >>> .el-range-input {
        width: 30% !important;
    }
    .search > .el-range-editor.el-input__inner {
        padding: 3px 0px;
    }
    .search > .el-select {
        width: 200px;
    }
    .search > .el-input {
        width: 300px;
    }
    .margin{
        margin-bottom: 10px;
        margin-right: 10px;
    }
</style>
