import {
    imageUrl,
    actionUrl
} from '@/config/public';
const column = {
    column: [
        {
            label: '分类名称',
            prop: 'name'
        },{
            label: '图片',
            prop: 'image',
            type: 'img'
        },
    ],
    columnIncrease: [
        {
            label: '分类名称',
            prop: 'name',
            span: 24,
            row: true,
            maxlength: 6,
            placeholder: '最多输入6个字',
            rules: [{
                required: true,
                message: "输入分类名称",
                trigger: "blur"
            }],

        },
        {
            label: '分类图片',
            prop: 'image',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/*',
            loadText: '附件上传中，请稍等',
            tip: '支持JPG、PNG，建议上传尺寸96px*96px',
            span: 24,
            propsHttp: {
                res: 'data',
                url: 'url',
                name: 'path',
                home: imageUrl
            },
            headers: {
                Authorization: ''
            },
            action: actionUrl,
            rules: [{
                required: true,
                message: '请上传图片',
                trigger: 'blur'
            }],
            display: false
        },
        {
            label: '排序',
            prop: 'sort',
            span: 24,
            type: 'number',
            controls:false,
            minRows: 0,
            maxRows: 99999999,
            placeholder:'数字越大越靠前',
            row: true,
            rules: [{
                required: true,
                message: '请输入排序',
                trigger: 'blur'
            }]
        },
        {
            label: '是否显示',
            prop: 'isShow',
            type: 'radio',
            dicData: [
                {
                    label: '显示',
                    value: 1
                },
                {
                    label: '不显示',
                    value: 2
                }
            ],
            span: 24,
            row: true,
            rules: [{
                required: true,
                message: "选择是否显示",
                trigger: "blur"
            }],

        }
    ]
}
export default column
