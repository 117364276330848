<template>
    <div class="main-content">
        <pagesHeader :title="`${id == 0 ? '新增':'编辑'}商品`" />
        <avue-form :option="option" v-model="form" ref="form"
                   :upload-error="uploadError"
                   :upload-delete="uploadDelete"
                   :upload-exceed="uploadExceed"
                   :upload-after="uploadAfter">
            <template slot="levelIds">
                <el-select v-model="form.levelIds" multiple filterable placeholder="请选择会员等级">
                    <el-option
                            v-for="item in levelIds"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </template>
            <template slot="hr">
                <hr /> </template>
            <template slot="spec" slot-scope="{ row, index }">
                <Specs v-if="show" :formSpecs="form.spec"></Specs>
                <SpecsList v-if="show" :formSpecs="form.spec" :formSpecsList="form.specList"></SpecsList>
            </template>
            <template slot="details">
                <avue-ueditor v-model="form.details" :options="uOptions"></avue-ueditor>
            </template>
        </avue-form>
        <div class="submit_button">
            <el-button class="submit_button_item" @click="$router.go(-1)">取消</el-button>
            <el-button class="submit_button_item" type="primary" @click="onSubmit(form)">保存</el-button>
        </div>
    </div>
</template>

<script>
    import column from "./column";
    import Specs from "./specs.vue"
    import SpecsList from "./specsList.vue"
    import { actionUrl } from '@/config/public';
    import { goodsAdd,classifyList,goodsView,goodsUpdate } from "@/api/goods/manage"
    export default {
        name: "increase",
        components: {
            Specs,
            SpecsList
        },
        data(){
            return{
                id: this.$route.params.id || 0,
                form: {
                    type: 1,
                    exchangeGroup: 1,
                    state: 1,
                    spec: [],
                    specList: [],
                    name: '',
                    levelIds: [],
                    cover: '',
                    banner: '',
                    details: '',
                },
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth: 150,
                    column: column.increaseColumn
                },
                value: '',
                show: false,
                levelIds: [],
                uOptions: {
                    action: actionUrl,
                    headers: {
                        Authorization: this.$store.state.user.Token
                    },
                    customConfig: {},
                    props: {
                        url: 'path',
                        res: 'data',
                        name: 'path'
                    }
                },
            }
        },
        watch:{
          'form.classifyStr'(value){
              console.log(value)
            this.form.oneClassifyId = '';
            this.form.oneClassifyName = '';
            this.form.twoClassifyId = '';
            this.form.twoClassifyName = '';
            if(value != ''){
              if(value.length > 0){
                value.forEach((item,index)=>{
                  if(index == 0){
                    this.form.oneClassifyId = item;
                  }else if(index == 1){
                    this.form.twoClassifyId = item;
                  }
                })
                this.getClassifyName(this.classifyList);
              }
            }
          },

        },
        mounted() {
            const cover = this.findObject(this.option.column, 'cover');
            cover.headers.Authorization = this.$store.state.user.Token;
            const banner = this.findObject(this.option.column, 'banner');
            banner.headers.Authorization = this.$store.state.user.Token;
          const video = this.findObject(this.option.column, 'video');
          video.headers.Authorization = this.$store.state.user.Token;

            this.$root.eventHub.$on('changeShopGoodsformSepc', (n)=>{
                this.form.spec = n
            })
            this.$root.eventHub.$on('changeShopGoodsformSpecList', (n)=>{
                this.form.specList = n
            })

          this.getClassifyList();

            if(this.$route.params.id != 0){
                this.GET_goodsView();
            }else{
                this.show = true;
            }
        },
        methods: {
          getClassifyName(list){
            list.forEach(item=>{
              if(item.id == this.form.oneClassifyId){
                this.form.oneClassifyName = item.name;
              }
              if(item.id == this.form.twoClassifyId){
                this.form.twoClassifyName = item.name;
              }
              if(item.children != undefined){
                this.getClassifyName(item.children);
              }
            })
          },
          getClassifyList(){
            classifyList().then(res=>{
              if(res.code == 200){
                const classifyStr = this.findObject(this.option.column, 'classifyStr');
                classifyStr.dicData = res.data;
                this.classifyList = res.data;
              }else{
                this.$message.error(res.msg)
              }
            }).catch(err=>{
              this.$message.error(err.msg)
            })
          },
            salaryChange(e,t){
                this.form[t] = e.target.value;
            },
            onSubmit(form){
                // console.log(form)
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                        let type = false;
                        this.form.specList.forEach((item,index) => {
                            if(item.image === '' || item.skuCode === '' || item.skuName === '' ){
                                return type = true
                            }
                        })
                        const obj = {};
                        let flag = this.form.specList.every((item) => {
                            return obj[item.skuCode] ? false : obj[item.skuCode] = true
                        })
                        if(type){
                            this.$message.error('请填写完整的规格参数');
                            console.log(this.form.specList)
                            done();
                        }else if(!flag){
                            this.$message.error('SKU编码不能重复');
                            done();
                        }else{
                            if(this.$route.params.id == 0){
                                this.GET_goodsAdd(form,done);
                            }else{
                                this.GET_goodsUpdate(form,done);
                            }
                        }

                    }
                })
            },
            GET_goodsView(){
                goodsView(this.$route.params.id).then(res=>{
                    if(res.code===200){
                        console.log(res)
                        this.form = res.data;
                this.form.classifyStr = res.data.classifyStr.split(',')
                        this.show = true;
                      console.log(this.form.banner)
                      if(this.form.banner.split(',').length == 10){
                        this.$el.getElementsByClassName('el-upload')[1].style.display = 'none'
                      }else{
                        this.$el.getElementsByClassName('el-upload')[1].style.display = ''
                      }
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            GET_goodsAdd(form,done){
                goodsAdd({
                  code: form.code,
                  name: form.name,
                  oneClassifyId: form.oneClassifyId, //一级分类id
                  oneClassifyName: form.oneClassifyName, //一级分类名称
                  twoClassifyId: form.twoClassifyId, //二级分类id
                  twoClassifyName: form.twoClassifyName, //二级分类名称
                  classifyStr: form.classifyStr.toString(),
                  sort: form.sort,
                  state: form.state,
                    cover: form.cover,
                    banner: form.banner,
                  video: form.video,
                    spec: form.spec,
                    specList: form.specList,
                    details: form.details,
                }).then(res=>{
                    if(res.code===200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            GET_goodsUpdate(form,done){
                goodsUpdate({
                    id: form.id,
                  code: form.code,
                  name: form.name,
                  oneClassifyId: form.oneClassifyId, //一级分类id
                  oneClassifyName: form.oneClassifyName, //一级分类名称
                  twoClassifyId: form.twoClassifyId, //二级分类id
                  twoClassifyName: form.twoClassifyName, //二级分类名称
                  classifyStr: form.classifyStr.toString(),
                  sort: form.sort,
                  state: form.state,
                  cover: form.cover,
                  banner: form.banner,
                  video: form.video,
                  spec: form.spec,
                  specList: form.specList,
                  details: form.details,
                }).then(res=>{
                    if(res.code===200){
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            uploadDelete(file) {
              return this.$confirm('是否确定移除？', {
                confirmButtonText: '确定',
                type: 'warning'
              }).then(() => {
                let array = this.form.banner.split(',');
                array.splice(file.uid * 1,1);
                if(array.length == 10){
                  this.$el.getElementsByClassName('el-upload')[1].style.display = 'none'
                }else{
                  this.$el.getElementsByClassName('el-upload')[1].style.display = ''
                }
              });
            },
            uploadError(error) {
                this.$message.error(error)
                // console.log(error)
            },
            uploadAfter(res, done,loading,column) {
              console.log(1111111111111111,res)
                done()
              if(res.code === 200){
                this.$nextTick(() => {
                  if(column.prop === 'cover'){
                    this.form.cover = res.data.path;
                  }else if (column.prop === 'video'){
                    this.form.video = res.data.path;
                  }else{
                    if(this.form.banner == ''){
                      this.form.banner = res.data.path;
                    }else{
                      let array = this.form.banner + res.data.path;
                      this.form.banner = array.split(',').filter(Boolean).toString()
                      if(this.form.banner.split(',').length == 10){
                        this.$el.getElementsByClassName('el-upload')[1].style.display = 'none'
                      }else{
                        this.$el.getElementsByClassName('el-upload')[1].style.display = ''
                      }
                      // console.log(this.form.banner)
                    }
                  }
                })
              }else{
                this.$message.error(res.msg)
              }
            },
            uploadExceed(limit, files, fileList, column){
                // console.log(limit, files, fileList, column)
                // this.$message.success('自定义查看方法,查看控制台')
                if(fileList.length == limit){
                    this.$message.error(`最多上传${limit}张图片`)
                }
            },
        }
    }
</script>

<style scoped>
    .submit_button{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .submit_button_item{
        padding: 12px 40px;
    }
    /deep/ .el-upload{
        width: 146px;
        height: 146px;
    }
    /deep/ .avue-upload__avatar{
         width: 146px;
         height: 146px;
     }
    /deep/ .el-icon-plus{
        width: 146px;
        height: 146px;
        line-height: 146px;
    }
</style>
